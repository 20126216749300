<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">{{$t('roles.edit_role')}}
                        <span class="text-muted pt-2 font-size-sm d-block">{{$t('roles.edit_role')}}</span></h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
                    <router-link to="/users/roles" class="btn btn-light font-weight-bolder">
                        <v-icon>mdi-chevron-left</v-icon>
                        {{$t('back')}}
                    </router-link>
                    <!--end::Button-->
                </div>
            </div>
            <div class="card-body">
                <div class="card-body p-0">
                    <div class="form-group">
                        <label for="name">{{$t('roles.role_name')}}</label>
                        <input v-model="item.name" type="text" class="form-control" id="name" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('roles.role_name')">
                        <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.name[0] }}
                        </span>
                    </div>
                    <div class="form-group">
                        <label for="description">{{$t('roles.description')}}</label>
                        <textarea v-model="item.description" type="text" class="form-control" id="description" :placeholder="$t('roles.description')"></textarea>
                    </div>
                    <div class="form-group table-responsive">
                        <table class="table">
                            <tbody>
                            <tr v-for="(per, index) in permissions" :key="index">
                                <th>{{ per[0] }}</th>
                                <td v-for="row in per[1]" :key="row.id">
                                    <b-form-checkbox-group size="lg" id="checkbox-group-2" v-model="item.permissions" name="permissions">
                                        <b-form-checkbox size="lg" :value="row.id">{{ row.display_name }}</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </td>
                                <td>
                                    <b-form-checkbox-group size="lg" id="checkbox-group-2" :checked="[isAllSelected(per[1])]" @change="selectAllPermissions($event,per[1])" name="permissions">
                                        <b-form-checkbox size="lg">{{ $t('all') }}</b-form-checkbox>
                                    </b-form-checkbox-group>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer pl-0 pr-0">
                    <b-button class="mt-2 mr-1" variant="primary" @click="editRole">{{$t('save')}}</b-button>
                </div>
            </div>

        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import _ from 'lodash';

    export default {
        name: "index",
        components: {},
        data() {
            return {
                idEdit: this.$route.params.id ? this.$route.params.id : '',
                item: {
                    name: '',
                    description: '',
                    permissions: [],
                },
                validation: null,
                permissions: [],
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Roles_Management"),route:'/users/roles'}, {title: this.$t('roles.edit_role')}]);
            this.getPermissions();
            this.getRole();
        },
        methods: {
            editRole() {
                ApiService.update("roles", this.idEdit, this.item).then(() => {
                    this.afterEditRole();
                }).catch((error) => {
                  this.validation = error.response ? error.response.data.errors : null;
                  console.log(this.validation);
                });
            },
            getRole() {
                ApiService.get("roles/" + this.idEdit).then((response) => {
                    this.item.name = response.data.data.name;
                    this.item.description = response.data.data.description;
                    this.item.permissions = response.data.data.permissions;
                }).catch(({errors}) => {
                    console.error(errors);
                });
            },
            getPermissions() {
                ApiService.get("permissions").then((response) => {

                    this.permissions = response.data.data.map((row) => {
                        return {group: row.name.split('.')[0], ...row};
                    });
                    this.permissions = Object.entries(_.groupBy(this.permissions, 'group'));

                }).catch(({errors}) => {
                    console.error(errors);
                });
            },

            afterEditRole() {
                this.$router.push({name: 'roles.index'});
            },
            selectAllPermissions(event, row) {
                // let _status = false;
                console.log(event);
                if (event[1]){
                    row.forEach((row)=>{
                        if (!this.item.permissions.includes(row.id)){
                            this.item.permissions.push(row.id);
                        }
                    });
                }else {
                    row.forEach((row)=>{
                        if (this.item.permissions.includes(row.id)){
                            let index = this.item.permissions.indexOf(row.id);
                            if (index > -1) {
                                this.item.permissions.splice(index, 1);
                            }
                        }
                    });
                }
            },
            isAllSelected(row) {
                let _status = true;
                row.forEach((row)=>{
                    if (!this.item.permissions.includes(row.id)){
                        _status = false;
                    }
                });
                return _status;
            }
        },
    };
</script>
